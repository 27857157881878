import React from 'react';
import {
  Form, Portal, Popup, Message, Icon
} from 'semantic-ui-react';
import { omit, pick } from 'lodash';
import { toJS } from 'mobx';
import Switch from '../Switch';
import ColoredCheckbox from '../ColoredCheckbox';
import MultipleDropdown from './MultipleDropdown';
import BetaModal from './Modal';
import Button from './Button';
import Input from './Input';
import Responsive from '../Responsive';

import '../../assets/css/ui/Form.module.scss';

import validate from '../../lib/validate';
import { __ } from '../../i18n';
import * as utils from '../../utils';

const styles = {
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  title: {
    fontSize: '1.429rem',
    color: '#000000',
    fontWeight: 700
  },
  toastTitle: {
    fontSize: '1.143rem',
    margin: 'auto',
  },
  icon: {
    fontSize: '20px',
    cursor: 'pointer',
    fontWeight: 300,
    color: 'rgba(0, 0, 0, 0.6)'
  },
};

export default class FormComponent extends Responsive {
  static Field = (props) => {
    const newProps = { ...props };

    const { error } = props;
    if (error) {
      newProps.error = true;
      newProps.action = (
        <Popup
          trigger={<Button round icon={{ name: 'exclamation triangle' }} transparent color="red" />}
          content={error}
        />
      );
    }

    return <Form.Field {...newProps} />;
  }

  static Checkbox = (props) => <ColoredCheckbox data-name={props.name} data-value={props.value} {...props} />;

  static Dropdown = (props) => <Form.Dropdown data-name={props.name} {...props} />;

  static MultipleDropdown = (props) => <MultipleDropdown data-name={props.name} {...props} />;

  static Select = (props) => <Form.Select data-name={props.name} {...props} />;

  static Radio = (props) => <Form.Radio data-name={props.name} data-value={props.value} {...props} />;

  static Group = Form.Group;

  static TextArea = (props) => <Input type="textarea" error={props.error} data-name={props.name} data-value={props.value} {...props} />;

  static FormTextArea = Form.TextArea;

  static Button = Form.Button;

  static Input = (props) => <Input error={props.error} data-name={props.name} data-value={props.value} {...props} />;

  static FormInput = (props) => {
    const newProps = { ...props };

    const { error } = props;
    if (error) {
      newProps.error = true;
      newProps.action = (
        <Popup
          trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
          content={error}
        />
      );
    }

    return <Form.Input {...newProps} />;
  }

  static Switch = (props) => <Form.Field control={Switch} {...props} />

  constructor(props) {
    super(props);
    this.state = {
      values: props.values || {},
      errors: props.errors || {},
      activeIndex: 0,
      mounted: false
    };
  }

  UNSAFE_componentWillMount() {
    const defaultValues = Array.isArray(this.defaultValues) ? Object.assign({}, ...this.defaultValues) : this.defaultValues;
    this.setState({ values: { ...defaultValues, ...this.props.values } });
  }

  componentDidMount() {
    this.setMountNodeIfNecessary();
    this.setState({ mounted: true });
  }

  componentDidUpdate() {
    this.setMountNodeIfNecessary();
  }

  setMountNodeIfNecessary() {
    const { header, modal } = this.props;

    if (modal) {
      let mountNode = document.querySelectorAll('.modal .actions');
      if (mountNode && !!mountNode.length) {
        mountNode = mountNode[mountNode.length - 1];
      } else {
        mountNode = document.body;
      }
      if (!this.state.mountNode) this.setState({ mountNode });
    }

    if (header) {
      let mountNodeHeader = document.querySelectorAll('.modal .header');
      if (mountNodeHeader && !!mountNodeHeader.length) {
        mountNodeHeader = mountNodeHeader[mountNodeHeader.length - 1];
      } else {
        mountNodeHeader = document.body;
      }
      if (!this.state.mountNodeHeader) this.setState({ mountNodeHeader });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ errors: nextProps.errors });
  }

  // Event handlers
  onInputChange = (e, { name, value, checked }) => {
    this.setValue(name, value !== undefined ? value : checked);
  }

  onTextInputChange = (e, { name, value, maxDigits = null }) => {
    if (maxDigits) value = value.substring(0, maxDigits);
    this.setValue(name, value);
  }

  onItemChange = (index, { name, value, checked }) => {
    this.setItem(name, value || checked, index);
  }

  onSelectionChange = (e, { name, value }, cb) => {
    this.setValue(name, value, cb);
  }

  // Set values
  setItem = (name, value, index, cb) => {
    const { values } = this.state;
    values[name][index] = value;
    this.setState({ values }, cb);
  }

  setValue = (name, value, cb) => {
    const { values } = this.state;
    values[name] = value;
    this.setState({ values }, cb);
  }

  removeObservableArray = (value) => {
    Object.keys(value).forEach((key) => {
      if (value[key] && value[key].$mobx) {
        value[key] = this.removeObservableArray(toJS(value[key]));
      }
    });
    return value;
  };

  pushValue = (name, value, cb) => {
    const { values } = this.state;
    values[name].push(value);
    this.setState({ values }, cb);
  }

  popValue = (name, index, cb) => {
    const { values } = this.state;
    values[name].splice(index, 1);
    this.setState({ values }, cb);
  }

  replaceValue = (name, value, index, cb) => {
    value = this.removeObservableArray(value);
    const { values } = this.state;
    values[name][index] = value;
    this.setState({ values }, cb);
  }

  toggleValue = (name, value, cb) => {
    const index = this.state.values[name].indexOf(value);
    if (index > -1) {
      this.popValue(name, index, cb);
    } else {
      this.pushValue(name, value, cb);
    }
  }

  handleSubmit = async (e, event, params) => {
    e.preventDefault();
    const values = await this.beforeSubmit({ ...this.state.values })
    const rules = Array.isArray(this.rules) ? Object.assign({}, ...this.rules) : this.rules;

    try {
      const validated = await validate.run(values, rules, this.maybes);
      if (params && params.resetErrorsOnSubmit) {
        this.setState({ errors: {} });
      }
      if (this.beforeOnSubmit) {
        this.beforeOnSubmit();
      }

      await this.props.onSubmit(values);

      if (this.afterOnSubmit) {
        this.afterOnSubmit();
      }

      return validated
    } catch(err) {
      if (this.onSubmitError) {
        this.onSubmitError({ err, values });
      }
      const errors = err.errors && Object.keys(err.errors).reduce((prev, current) => {
        prev[current] = utils.handleError(err.errors[current].message);
        return prev;
      }, {});

      if (params && params.errorsModal && errors) {
        this.props.store.appends.push(
          <BetaModal
            id="ErrorModal"
            onClose={() => this.props.store.appends.pop()}
            actions={[
              <Button
                data-action="ok"
                round
                text={__('Ok')}
                onClick={() => this.props.store.appends.pop()}
              />
            ]}
            header={params.errorsModal.title ? params.errorsModal.title : __('Error')}
            content={(
              <div>
                <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{params && params.errorsModal.message}</span>
              </div>
            )}
          />
        );
      }
      this.setState({ errors }, () => {
        if (this.onError) {
          this.onError({ ...this.state.errors });
        }
      });
    }
  }

  beforeSubmit = (values) => values

  handleCancel = () => {
    Promise.resolve(this.beforeSubmit({ ...this.state.values }))
      .then((values) => this.props.onCancel(values));
  }

  handleClose = () => {
    Promise.resolve(this.beforeSubmit({ ...this.state.values }))
      .then((values) => this.props.onClose(values));
  }

  handleNext = (e) => {
    e.preventDefault();
    const { activeIndex } = this.state;
    const values = pick(this.state.values, Object.keys(this.defaultValues[activeIndex]));
    validate.run(values, this.rules[activeIndex], this.maybes)
      .then(() => this.setState({ activeIndex: activeIndex + 1, errors: {} }))
      .catch((err) => {
        const errors = err.errors && Object.keys(err.errors).reduce((prev, current) => {
          prev[current] = utils.handleError(err.errors[current].message);
          return prev;
        }, {});
        this.setState({ errors }, () => {
          if (this.onError) {
            this.onError({ ...this.state.errors });
          }
        });
      });
  }

  handleBack = () => {
    const { activeIndex } = this.state;

    this.setState({ activeIndex: activeIndex - 1 });
  }

  renderHeader = (header, headerItem, onClose, invertCloseButton) => (
    <div style={{ ...styles.header, padding: this.props.hasDivider || this.props.paddingHeader ? '12px' : '0px', borderBottom: this.props.toast || this.props.hasDivider ? '1px solid #f1f3f5' : null }}>
      {invertCloseButton
        && onClose && (
          <div>
            {' '}
            <Icon name="times close" style={{ ...styles.icon }} onClick={() => { onClose(); }} />
            {' '}
          </div>
        )}
      {header
        ? <span style={this.props.toast ? { ...styles.title, ...styles.toastTitle } : { ...styles.title }}>{header}</span>
        : <div />}
      <div className="header-items">
        {
          headerItem
        }
        {!invertCloseButton
          && onClose && <Icon name="times close" style={styles.icon} onClick={() => { onClose(); }} />}
      </div>
    </div>
  );

  render() {
    const { errors, mounted } = this.state;
    const {
      modal,
      loading,
      onSubmit,
      submitButton,
      submitButtonIcon,
      onCancel,
      onPressNext,
      onPressBack,
      cancelButton,
      cancelButtonStyle,
      renderErrors,
      header,
      actionButtons,
      actionButtonsLeft,
      actionButtonsRight,
      withPortal,
      steps,
      tabs,
      renderNextButton,
      disableBackButton,
      red
    } = this.props;
    const newProps = omit(
      this.props,
      ['modal', 'errors', 'onSubmit', 'submitButton', 'onCancel', 'cancelButton', 'cancelButtonStyle', 'store', 'values', 'loading', 'renderErrors', 'actionButtons', 'actionButtonsLeft', 'actionButtonsRight']
    );

    const submitProps = (typeof submitButton === 'string') ? { text: submitButton } : submitButton;

    let renderedSubmitButton = (submitButton || onSubmit)
      ? (
        <Button
          data-action="submit"
          round
          loading={loading}
          disabled={loading}
          icon={submitButtonIcon ? { name: submitButtonIcon } : null}
          text={submitButton || __('Submit')}
          red={red}
          onClick={onSubmit}
          {...submitProps}
        />
      ) : '';

    if (submitProps && submitProps.isActionButtom) {
      renderedSubmitButton = null;
    }

    const renderedBackButton = onPressBack && this.props.add ? (
      <Button
        data-action="back"
        transparent
        round
        disabled={disableBackButton}
        text={__('Back')}
        onClick={onPressBack}
      />
    ) : '';

    const renderedNextButton = onPressNext
      ? (
        <Button
          data-action="submit"
          loading={loading}
          round
          disabled={loading}
          text={__('Next')}
          onClick={onPressNext}
        />
      ) : '';

    const cancelProps = (typeof cancelButton === 'string') ? { text: cancelButton, style: cancelButtonStyle || {} } : cancelButton;

    let renderedCancelButton = (cancelButton || onCancel)
      ? (
        <Button
          data-action="cancel"
          transparent
          disabled={loading}
          round
          text={cancelProps.text !== undefined ? cancelProps.text : __('Cancel')}
          onClick={onCancel}
          {...cancelProps}
        />
      ) : '';
    if (cancelProps && cancelProps.isActionButtom) {
      renderedCancelButton = null;
    }

    let actions = (
      <div>
        {renderedCancelButton}
        {actionButtons}
        {renderedBackButton}
        {(renderNextButton && this.props.add) ? renderedNextButton : renderedSubmitButton}
      </div>
    );
    if ((actionButtonsLeft && actionButtonsLeft.length > 0) || (actionButtonsRight && actionButtonsRight.length > 0)) {
      actions = (
        <div style={{
          display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {renderedCancelButton}
            {actionButtonsLeft}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifySelf: 'flex-end' }}>
            {actionButtonsRight}
            {renderedBackButton}
            {(renderNextButton && this.props.add) ? renderedNextButton : renderedSubmitButton}
          </div>
        </div>
      );
    }

    const errorKeys = errors && Object.keys(errors);

    return (
      <div>
        {(steps && tabs)
          ? (
            <div style={this.props.add && { paddingBottom: 70 }}>
              {this.props.add ? steps : tabs}
            </div>
          ) : ''}
        {!!header
          && (
            <div style={{ width: '100%' }}>
              <Portal
                open={mounted}
                mountNode={this.state.mountNodeHeader}
                className="portal-div"
              >
                {this.renderHeader(header.title, header.headerItem, header.onClose, header.invertCloseButton)}
              </Portal>
            </div>
          )}
        <Form
          //as="div"
          error={errorKeys && errorKeys.length > 0}
          warning
          {...newProps}
        >

          {
            renderErrors && errorKeys && errorKeys.length > 0
            && (
              <Message error>
                {errorKeys.map((key) => <p key={key}>{errors[key]}</p>)}
              </Message>
            )
          }
          {this.props.children}
          <div style={{ width: '100%' }}>
            {
              (modal && (renderedSubmitButton || renderedCancelButton || actionButtons || withPortal))
                ? (
                  <Portal
                    open={mounted}
                    mountNode={this.state.mountNode}
                    className={(actionButtonsLeft && actionButtonsLeft.length > 0) || (actionButtonsRight && actionButtonsRight.length > 0) ? 'portal-div' : ''}
                  >
                    {actions}
                  </Portal>
                )
                : actions
            }
          </div>
        </Form>
      </div>
    );
  }
}
