import React from 'react';
import CurrencyInput from 'react-currency-input';
import { Icon, Popup } from 'semantic-ui-react';

import '../../assets/css/ui/Input.module.scss';

// Fix the focus error on react-currency-input lib
const componentDidMount_super = CurrencyInput.prototype.componentDidMount;
CurrencyInput.prototype.componentDidMount = function () {
  if (!this.props.autoFocus) {
    const setSelectionRange_super = this.theInput.setSelectionRange;
    this.theInput.setSelectionRange = () => { };
    // eslint-disable-next-line prefer-rest-params
    componentDidMount_super.call(this, ...arguments);
    this.theInput.setSelectionRange = setSelectionRange_super;
  } else {
    // eslint-disable-next-line prefer-rest-params
    componentDidMount_super.call(this, ...arguments);
  }
};
const componentDidUpdate_super = CurrencyInput.prototype.componentDidUpdate;
CurrencyInput.prototype.componentDidUpdate = function () {
  if (!this.props.autoFocus) {
    const setSelectionRange_super = this.theInput.setSelectionRange;
    this.theInput.setSelectionRange = () => { };
    // eslint-disable-next-line prefer-rest-params
    componentDidUpdate_super.call(this, ...arguments);
    this.theInput.setSelectionRange = setSelectionRange_super;
  } else {
    // eslint-disable-next-line prefer-rest-params
    componentDidMount_super.call(this, ...arguments);
  }
};

export default class Input extends React.Component {
  renderInput = (props) => {
    const {
      icon, 
      onClear, 
      check, 
      selected, 
      error, 
      label, 
      description, 
      children, 
      cssTags, 
      actionLeft, 
      actionRight, 
      popupTrigger, 
      popupIconContent, 
      ...inputProps
    } = props;
    const {
      value, type, id, placeholder, onChange, disabled, onKeyPress, iconCircleStyle
    } = inputProps;

    if (type === 'search') {
      return (
        <div className="search-input">
          <Icon className="search" data-testid="search-icon" />

          <input
            placeholder="Busca"
            {...inputProps}
            type="text"
            value={value}
          />

          {value && <Icon className="times" data-testid="close-icon" onClick={onClear} style={iconCircleStyle} />}
        </div>
      );
    }

    if (type === 'percentage') {
      return (
        <div className="text-input percentage">
          <input
            data-testid="percentage-input"
            placeholder="0"
            value={value}
            {...inputProps}
            type="number"
            onKeyPress={(e) => {
              if (e.key === 'E' || e.key === 'e' || e.key === '-') e.preventDefault();
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              const maxPercentage = inputProps.maxPercentage ? inputProps.maxPercentage : 100;
              if (newValue > maxPercentage || newValue < 0) {
                e.preventDefault();
                return;
              }
              onChange(newValue);
            }}
          />
          {children}

          <Icon className="percentage align-right" />
        </div>
      );
    }

    if (type === 'radio') {
      const classIcon = check ? 'circle check selected' : 'scrubber selected';
      return (
        <label
          htmlFor={id}
          id={id}
          className={`radio-input ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        >
          {placeholder || value}
          <input {...inputProps} checked={selected} />
          {children}
          {selected ? <Icon className={classIcon} /> : <Icon className="circle unselected" />}
        </label>
      );
    }

    if (type === 'product') {
      return (
        <label
          htmlFor={id}
          id={id}
          className={`radio-input ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        >
          {placeholder || value}
          <input {...inputProps} />
          {children}
          {selected ? <Icon className="circle check selected" /> : <Icon className="circle unselected" />}
        </label>
      );
    }

    if (type === 'checkbox') {
      return (
        <label
          htmlFor={id}
          id={id}
          className={`checkbox-input ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        >
          {placeholder || value}
          <input {...inputProps} />
          {children}
          {selected ? <Icon className="square check selected" /> : <Icon className="square unselected" />}
        </label>
      );
    }

    if (type === 'big-radio' || type === 'big-checkbox') {
      return (
        <label
          htmlFor={id}
          id={id}
          className={`radio-input checkbox-input big ${selected ? 'selected' : ''} ${disabled ? 'disabled' : ''}`}
        >
          {icon && (
            <div className="icon-border">
              <Icon className={icon} />
            </div>
          )}
          <div className="content">
            <p className="label">{label}</p>
            <p className="description">{description}</p>
          </div>
          <input {...inputProps} type={type === 'big-radio' ? 'radio' : 'checkbox'} />
          {children}
          {selected ? <Icon className={`${type === 'big-radio' ? 'scrubber' : 'circle check'} selected`} /> : <Icon className="circle unselected" />}
        </label>
      );
    }

    if (type === 'currency') {
      return (
        <CurrencyInput
          style={{
            height: '47px',
            fontSize: '16px',
            border: this.props.error ? '1px solid rgb(191, 38, 0)' : '1px solid rgba(34,36,38,.15)',
            color: value ? '#000000' : '#999999',
          }}
          prefix="R$"
          decimalSeparator=","
          thousandSeparator="."
          precision={2}
          value={value}
          onChangeEvent={(e) => {
            e.preventDefault();
            onChange(e);
          }}
        />
      );
    }

    if (type === 'textarea') {
      return (
        <textarea className={cssTags} {...inputProps} />
      );
    }

    let classForTextNumber = icon && icon.before ? ' padding-left ' : '';
    classForTextNumber += icon && icon.after ? ' padding-right ' : '';

    const actionLeftStyle = actionLeft
      ? {
        borderTopLeftRadius: '0px',
        borderBottomLeftRadius: '0px',
      } : {};

    const actionRightStyle = actionRight
      ? {
        borderTopRightRadius: '0px',
        borderBottomRightRadius: '0px',
      } : {};

    return (
      <div style={this.props.containerInputStyle} className="text-input">
        {icon && icon.before && <Icon className={`${icon.before.name} align-left`} style={{ color: props.red ? '#BF2600' : null }} {...icon.before} />}
        {actionLeft}
        <input
          className={classForTextNumber}
          type="text"
          placeholder=""
          value={value}
          onKeyPress={onKeyPress}
          {...inputProps}
          style={
            icon && icon.before
              ? {
                paddingLeft: '40px', ...actionLeftStyle, ...actionLeftStyle, ...this.props.inputStyle, borderColor: props.red ? '#BF2600' : null, color: props.red ? '#BF2600' : null
              }
              : {
                ...actionLeftStyle, ...actionRightStyle, ...this.props.inputStyle, borderColor: props.red ? '#BF2600' : null, color: props.red ? '#BF2600' : null
              }
          }
        />
        {actionRight}
        {children}
        {icon?.after && (
          popupTrigger ? (
            <Popup
              trigger={(
                <Icon 
                className={`${icon.after.name} align-right`} 
                style={icon.after.style} 
                data-testid='icon-after-popup-trigger'
                {...icon.after} 
                />
              )}
              content={popupIconContent}
              position="bottom"
              hideOnScroll
              on="click"
              style={{ borderRadius: '0.25rem' }}
            />
          ):(
            <Icon className={`${icon.after.name} align-right`} style={icon.after.style} {...icon.after} />
          )
        )}
      </div>
    );
  }

  render() {
    const {
      error, className, style, label, id, labelStyle, footer, ...props
    } = this.props;

    return (
      <div className={`input-component ${className || ''} ${error ? 'input-error' : ''}`} style={style}>
        {label && <label htmlFor={id} id={id} style={{ display: 'inline-block', marginBottom: '4px', ...labelStyle }}>{label}</label>}
        {this.renderInput(props)}
        {footer}
        {error && (
          <div className="error-warning" style={error && error.style && error.style}>
            <Icon className="circle exclamation" />
            <span>{typeof error !== 'string' && error.text ? error.text : error}</span>
          </div>
        )}
      </div>
    );
  }
}
